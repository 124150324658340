import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import moment from "moment";

const tableColsServiceOrganization = [
  {
    text: "ID",
    value: "id",
    width: 25,
    sort: true
  },
  {
    text: "Город",
    value: "city",
    renderFunction: value =>
      `<div style="display: inline-grid"><span>${value.city_details.name}</span>
          <span style="background-color: #95c23d; border-radius: 15px; color: #fff; padding: 2px 5px" >${
            dictionariesHelper.timezone[value.city_details.timezone]
          }</span></div>`
    // sort: true
  },
  {
    text: "Название",
    value: "name",
    sort: true
  },
  {
    text: "Техники",
    value: "service_organizations_technicians_count"
  },
  {
    text: "Контроллеров",
    value: "service_organizations_objects_controllers_count",
    src: "/img/table_icons/tabbar__icon_5.svg",
    width: 20
  },
  {
    text: "Объектов",
    value: "service_organizations_objects_count",
    src: "/img/table_icons/tabbar__icon_3.svg",
    width: 20
  },
  {
    text: "Компаний",
    value: "service_organizations_organizations_count",
    src: "/img/table_icons/tabbar__icon_2.svg",
    width: 20
  },

  {
    text: "Дата создания",
    value: "created_at",
    userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
    renderFunction: value => {
      return moment(value.created_at * 1000).format("DD.MM.YYYY HH:mm");
    },
    sort: true
  }
];

export default tableColsServiceOrganization;
